import { Language } from '../../session/models/session-record';
import { ReminderPreference } from '../../../../account/preferences/shared/models/customer-notifications';

export interface CustomerNotificationPreferencesRecord {
  emailNotifications: NotificationRecord[];
  smsNotifications: NotificationRecord[];
  smsNotificationsSubscriptionTypes: NotificationSubscriptionType[];
  emailNotificationsSubscriptionTypes: NotificationSubscriptionType[];
}

export interface NotificationRecord {
  methodName: string;
  methodValue: string;
  languageCode: Language;
  subscriptionTypes: NotificationSubscriptionType[];
  customerReminders?: ReminderPreference[];
}

export enum NotificationSubscriptionType {
  ORDER_APPROVAL = 'ORDER_APPROVAL',
  TRUCK_ETA = 'TRUCK_ETA',
  TRUCK_ETA30 = 'TRUCK_ETA30',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  TRUCK_ETA_ON_DEMAND = 'TRUCK_ETA_ON_DEMAND',
  TRUCK_2HR_CUTOFF = 'TRUCK_2HR_CUTOFF',
  ORDER_DELIVERED = 'ORDER_DELIVERED',
  ORDER_ENTRY_CUTOFF_CLOSURE = 'ORDER_ENTRY_CUTOFF_CLOSURE',
}
