<div class="mobile-search-screen">
  <div class="searching-bar-mobile">
    <naoo-search-bar
      class="naoo-searching-bar"
      [isMobile]="true"
      (closeMobileSearchModalEmitter)="closeModal()"
    >
    </naoo-search-bar>
  </div>
  <hr />
</div>
