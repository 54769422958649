import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { DeviceIdentifierService } from '../../shared/services/device-identifier/device-identifier.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'naoo-mobile-search-modal',
  templateUrl: './mobile-search-modal.component.html',
  styleUrls: ['./mobile-search-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileSearchModalComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject();

  constructor(
    private readonly dialogRef: MatDialogRef<MobileSearchModalComponent>,
    private readonly deviceIdentifierService: DeviceIdentifierService,
  ) {}

  ngOnInit() {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        if (!isMobile) {
          this.closeModal();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  closeModal() {
    this.dialogRef.close();
  }
}
