import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from '@angular/core';
import { HeaderLinkType, MoreToolsLink } from '../header.types';
import { naooAnimations } from '../../shared/animations/animations';
import {
  CustomerPermission,
  Language,
} from '../../core/services/session/models/session-record';
import { LocalizationService } from '../../shared/services/translation/localization.service';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-more-tools',
  templateUrl: './more-tools.component.html',
  styleUrls: ['./more-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [naooAnimations.showHide],
  standalone: true,
  imports: [MatIcon, TranslateModule, NgClass, MatMenuModule],
})
export class MoreToolsComponent {
  static readonly MORE_TOOLS_PERMISSIONS = [
    CustomerPermission.GordonRestaurantProAccess,
    CustomerPermission.OnlinePaymentAccess,
    CustomerPermission.CycleMenuManagementAccess,
    CustomerPermission.DiningRdAccess,
    CustomerPermission.GordonReportingAccess,
    CustomerPermission.GordonEducationReportingAccess,
    CustomerPermission.GordonHealthReportingAccess,
    CustomerPermission.ExperienceAccess,
    CustomerPermission.ResourcesAccess,
  ];

  protected readonly localizationService = inject(LocalizationService);
  private readonly reportingTitle = 'TITLE.GORDON_REPORTING';
  protected isMenuOpen = signal<boolean>(false);
  protected showHideAnimationStatus = signal<string>('hide');
  @Input() permissions: CustomerPermission[];
  @Input() isDisabled: boolean;
  @Input() isMobile: boolean;
  @Output() headerRoutingEvent = new EventEmitter<HeaderLinkType>();

  redirectTo(name: HeaderLinkType): void {
    this.headerRoutingEvent.emit(name);
  }

  toggleSubMenu(): void {
    this.isMenuOpen.set(!this.isMenuOpen());
    this.showHideAnimationStatus.set(this.isMenuOpen() ? 'show' : 'hide');
  }

  get moreToolsLinks(): Record<string, MoreToolsLink> {
    return {
      [CustomerPermission.GordonRestaurantProAccess]: {
        translationName: 'TITLE.GORDON_RESTAURANT_PRO',
        headerLinkType: HeaderLinkType.GORDON_RESTAURANT_PRO,
      },
      [CustomerPermission.OnlinePaymentAccess]: {
        translationName: 'TITLE.ONLINE_PAYMENT',
        headerLinkType: HeaderLinkType.ONLINE_PAYMENT,
      },
      [CustomerPermission.CycleMenuManagementAccess]: {
        translationName: 'TITLE.CYCLE_MENU_MANAGEMENT',
        headerLinkType: HeaderLinkType.CYCLE_MENU_MANAGEMENT,
      },
      [CustomerPermission.DiningRdAccess]: {
        translationName: 'TITLE.DINING_RD',
        headerLinkType: HeaderLinkType.DINING_RD,
      },
      [CustomerPermission.GordonReportingAccess]: {
        translationName: this.reportingTitle,
        headerLinkType:
          this.localizationService.currentLanguage === Language.fr
            ? HeaderLinkType.GORDON_REPORTING_FR
            : HeaderLinkType.GORDON_REPORTING,
      },
      [CustomerPermission.GordonEducationReportingAccess]: {
        translationName: this.reportingTitle,
        headerLinkType:
          this.localizationService.currentLanguage === Language.fr
            ? HeaderLinkType.GORDON_EDUCATION_REPORTING_FR
            : HeaderLinkType.GORDON_EDUCATION_REPORTING,
      },
      [CustomerPermission.GordonHealthReportingAccess]: {
        translationName: this.reportingTitle,
        headerLinkType:
          this.localizationService.currentLanguage === Language.fr
            ? HeaderLinkType.GORDON_HEALTH_REPORTING_FR
            : HeaderLinkType.GORDON_HEALTH_REPORTING,
      },
      [CustomerPermission.ExperienceAccess]: {
        translationName: 'TITLE.EXPERIENCE',
        headerLinkType: HeaderLinkType.EXPERIENCE,
      },
      [CustomerPermission.ResourcesAccess]: {
        translationName: 'TITLE.RESOURCES',
        headerLinkType: HeaderLinkType.RESOURCES,
      },
    };
  }
}
