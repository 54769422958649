import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  customerNotificationEntryAdapter,
  CustomerNotificationPreferencesState,
  CustomerNotificationsConstants,
  NotificationsState,
} from './customer-notification-preferences.state';
import { selectActiveCustomer } from '../session/session.selectors';
import { SessionActiveCustomer } from '../../services/session/models/session-record';
import {
  convertLanguageToNotificationLanguage,
  CustomerNotification,
  EditableStatus,
  NotificationMethodCode,
  Notifications,
  NotificationsDisplayConstants,
  ReminderDetails,
  ReminderMessage,
} from '../../../account/preferences/shared/models/customer-notifications';
import { selectCurrentUrl } from '../router/router.selectors';
import { NaooConstants } from '../../../shared/NaooConstants';

export const selectCustomerNotificationPreferencesSelector =
  createFeatureSelector<CustomerNotificationPreferencesState>(
    'customerNotifications',
  );

export const selectPreferencesNotifications = createSelector(
  selectCustomerNotificationPreferencesSelector,
  selectCurrentUrl,
  (state, currentUrl) => {
    return {
      emailNotifications:
        state.customerNotificationPreferences?.emailNotifications || [],
      smsNotifications:
        state.customerNotificationPreferences?.smsNotifications || [],
      showModal:
        state.hasError &&
        !state.hasRefreshed &&
        !!currentUrl?.startsWith(NaooConstants.PREFERENCES_PATH),
    };
  },
);

export const selectEmailNotifications = createSelector(
  selectCustomerNotificationPreferencesSelector,
  selectActiveCustomer,
  (state, customer) =>
    transformState(state, NotificationMethodCode.Email, customer),
);

export const selectSmsNotifications = createSelector(
  selectCustomerNotificationPreferencesSelector,
  selectActiveCustomer,
  (state, customer) =>
    transformState(state, NotificationMethodCode.Sms, customer),
);

const selectEmailNotificationEntityState = createSelector(
  selectCustomerNotificationPreferencesSelector,
  (state) => state.email.notifications,
);

const selectSmsNotificationEntityState = createSelector(
  selectCustomerNotificationPreferencesSelector,
  (state) => state.sms.notifications,
);

export const selectShouldDisplayErrorPage = createSelector(
  selectCustomerNotificationPreferencesSelector,
  (state): boolean => state.hasRefreshed && state.hasError,
);

export const selectEmailNotificationStates = createSelector(
  selectEmailNotificationEntityState,
  customerNotificationEntryAdapter.getSelectors().selectAll,
);

export const selectSmsNotificationStates = createSelector(
  selectSmsNotificationEntityState,
  customerNotificationEntryAdapter.getSelectors().selectAll,
);

const SUPPORTED_REMINDER_MESSAGES = [
  ReminderMessage.MEAT,
  ReminderMessage.ORDER,
  ReminderMessage.CUTOFF,
  ReminderMessage.INVOICE,
];

function transformState(
  state: CustomerNotificationPreferencesState,
  notificationMethodCode: NotificationMethodCode,
  customer: SessionActiveCustomer,
): Notifications | undefined {
  let notificationState: NotificationsState;
  let isRemindersEnabled: boolean;

  switch (notificationMethodCode) {
    case NotificationMethodCode.Email:
      notificationState = state.email;
      isRemindersEnabled = false;
      break;
    case NotificationMethodCode.Sms:
      notificationState = state.sms;
      isRemindersEnabled = true;
      break;
  }

  if (!notificationState.hasLoaded) {
    return undefined;
  }

  const ordinals = notificationState.notifications.ids as number[];
  const notifications: CustomerNotification[] = ordinals.map((ordinal) => {
    const editedRecordState = notificationState.notifications.entities[ordinal];
    // We currently only allow one language selection; default to first entry
    const notificationLanguage = convertLanguageToNotificationLanguage(
      editedRecordState.languages[0],
    );

    const editableStatus = transformEditableStatus(
      notificationState.editingOrdinal,
      editedRecordState.ordinal,
    );

    return {
      ordinal: editedRecordState.ordinal,
      name: editedRecordState.name,
      contactInfo: editedRecordState.contactInfo,
      language: notificationLanguage,
      editableStatus: editableStatus,
      methodCode: editedRecordState.type,
      subscriptionTypes: editedRecordState.subscriptionTypes,
      customerReminders: editedRecordState.customerReminders,
    };
  });

  if (
    notificationState.editingOrdinal ===
    CustomerNotificationsConstants.newNotificationOrdinal
  ) {
    notifications.unshift({
      ordinal: CustomerNotificationsConstants.newNotificationOrdinal,
      name: '',
      contactInfo: '',
      language: null,
      editableStatus: EditableStatus.Active,
      methodCode: null,
      subscriptionTypes: null,
      customerReminders: null,
    });
  }

  const enabledSubscriptionTypes =
    notificationMethodCode == NotificationMethodCode.Email
      ? state.customerNotificationPreferences
          .emailNotificationsSubscriptionTypes
      : state.customerNotificationPreferences.smsNotificationsSubscriptionTypes;

  return {
    customer: {
      name: customer.name,
      address: customer.address.line1,
    },
    totalNotifications: ordinals.length,
    customerNotifications: notifications,
    isAddDisabled:
      isAtOrOverLimit(ordinals.length) ||
      notificationState.editingOrdinal !==
        CustomerNotificationsConstants.noneSelectedOrdinal,
    enabledSubscriptionTypes,
    reminderDetails: isRemindersEnabled ? getReminderDetails(customer) : null,
  };
}

function getReminderDetails(customer: SessionActiveCustomer): ReminderDetails {
  return {
    customerTimezone: customer.timeZone,
    supportedReminderMessages: SUPPORTED_REMINDER_MESSAGES,
    countryCode: customer.countryCode,
  };
}

function transformEditableStatus(
  editingOrdinal: number,
  ordinal: number,
): EditableStatus {
  if (editingOrdinal === CustomerNotificationsConstants.noneSelectedOrdinal) {
    return EditableStatus.Enabled;
  } else if (editingOrdinal === ordinal) {
    return EditableStatus.Active;
  } else {
    return EditableStatus.Disabled;
  }
}

export function isAtOrOverLimit(notificationCount: number) {
  return (
    notificationCount >= NotificationsDisplayConstants.maxNotificationsCount
  );
}
