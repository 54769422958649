<form class="searching-wrapper" (ngSubmit)="performSearch(searchText.value)">
  <mat-form-field class="searching-box">
    @if (isMobile) {
      <div>
        <mat-icon
          class="arrowback-icon"
          type="button"
          (click)="closeModal()"
          (keydown)="closeModal()"
        >
          arrow_back
        </mat-icon>
      </div>
    }
    <input
      matInput
      type="text"
      class="searching-box"
      [placeholder]="placeholderText() | translate"
      #searchText
      name="searchText"
      [(ngModel)]="inputText"
      (ngModelChange)="
        updateAriaLabelAndPlaceholderText(); getAutocompleteSuggestions($event)
      "
      autocomplete="off"
      [matAutocomplete]="auto"
      [attr.aria-label]="ariaText() | translate"
      [maxlength]="MAX_INPUT_LENGTH"
      [disabled]="isDisabled"
    />
    @if (inputText()?.length) {
      <button
        mat-button
        class="btn-clear-icon"
        type="button"
        (click)="clearAndFocus()"
        [attr.aria-label]="'SEARCH.CANCEL' | translate"
        [disabled]="isDisabled"
      >
        <mat-icon class="search-clear-icon">clear_icon</mat-icon>
      </button>
    }
    <button
      class="searching-button"
      [ngClass]="{
        disabled: isDisabled,
      }"
      type="button"
      (click)="performSearch(searchText.value.toString())"
      [attr.aria-label]="'SEARCH.TITLE' | translate"
      [disabled]="isDisabled"
    >
      <mat-icon>search_icon_v2</mat-icon>
      <span>
        {{ (!isMobile ? 'SEARCH.TITLE' : '') | translate }}
      </span>
    </button>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="performSearch($event.option.value)"
      [disableRipple]="true"
    >
      @for (option of options(); track option) {
        <mat-option [value]="option">
          <div innerHtml="{{ option | bold: inputText() }}"></div>
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</form>
