import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { HamburgerMenuComponent } from './hamburger-menu/hamburger-menu.component';
import { LogoutModule } from '../logout/logout.module';
import { CartIconModule } from '../cart/cart-icon/cart-icon.module';
import { DeliveryScheduleWidgetModule } from '../delivery-schedule-widget/delivery-schedule-widget.module';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { RouterModule } from '@angular/router';
import { SearchButtonMobileComponent } from './search-button-mobile/search-button-mobile.component';
import { MobileSearchModalComponent } from './mobile-search-modal/mobile-search-modal.component';
import { AccountMenuComponent } from './sub-header/account-menu/account-menu.component';
import { NaooIconService } from '../shared/services/images/naoo-icon.service';
import { NaooConstants } from '../shared/NaooConstants';
import { OrderToolsComponent } from './sub-header/order-tools/order-tools.component';
import { HeaderTabsComponent } from './header-tabs/header-tabs.component';
import { SupportContactModalComponent } from './support-contact-modal/support-contact-modal.component';
import { SelectOrderMethodWidgetModule } from '../select-order-method-widget/select-order.method.widget.module';
import { MoreToolsComponent } from './more-tools/more-tools.component';
import { ImportOrderModule } from '../import-order/import-order.module';
import { SearchBarComponent } from './search-bar/search-bar.component';

const { icons } = NaooConstants;

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatGridListModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    CartIconModule,
    DeliveryScheduleWidgetModule,
    LogoutModule,
    TranslateModule.forChild(),
    MatButtonModule,
    SharedModule,
    RouterModule,
    SelectOrderMethodWidgetModule,
    ImportOrderModule,
    MoreToolsComponent,
    SearchBarComponent,
  ],
  declarations: [
    HeaderComponent,
    HamburgerMenuComponent,
    SubHeaderComponent,
    SearchButtonMobileComponent,
    MobileSearchModalComponent,
    SupportContactModalComponent,
    AccountMenuComponent,
    OrderToolsComponent,
    HeaderTabsComponent,
  ],
  exports: [
    HeaderComponent,
    AccountMenuComponent,
    HamburgerMenuComponent,
    HeaderTabsComponent,
  ],
})
export class HeaderModule {
  readonly headerIcons = [icons.unavailable, icons.open_new_tab];

  constructor(public naooIconService: NaooIconService) {
    naooIconService.registerIcons(this.headerIcons);
  }
}
