import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bold',
  standalone: true,
})
export class BoldPipe implements PipeTransform {
  transform(text: string, search: string): string {
    if (search && text) {
      // Escape special characters in the search string for use in regex
      let pattern = search.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
      pattern = pattern
        .split(' ')
        .filter((t) => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');

      // Split the text based on the matches and identify the matching segments
      const parts = text.split(regex);
      const matches = text.match(regex) || [];

      // Combine non-matching parts with matching segments
      let result = '';
      parts.forEach((part, index) => {
        if (part) {
          result += `<b>${part}</b>`;
        }
        if (matches[index]) {
          result += matches[index];
        }
      });

      return result;
    } else {
      return text;
    }
  }
}
