<div class="no-results-card">
  <div
    class="content-box"
    [ngClass]="{ 'content-box-full': !(showExpo() | async) }"
  >
    <div class="search-tips-box">
      <div class="search-box-title">{{ 'SEARCH.SUBTITLE' | translate }}</div>
      <div class="search-tips-title">
        <mat-icon svgIcon="search_icon_v2"></mat-icon
        >{{ 'SEARCH.TIPS' | translate }}
      </div>
      <ul>
        <li class="search-tips">{{ 'SEARCH.CHECK_SPELLING' | translate }}</li>
        <li class="search-tips">{{ 'SEARCH.SIMPLE_TERMS' | translate }}</li>
        <li class="search-tips">{{ 'SEARCH.LESS_SPECIFIC' | translate }}</li>
        @if (isZeroWithOG) {
          <li class="search-tips">
            {{ 'SEARCH.TURN_OFF_ORDER_GUIDE' | translate }}
          </li>
        }
      </ul>
    </div>
  </div>
  @if (showExpo() | async) {
    <div class="content-box">
      <div class="ask-expo-box">
        <div class="expo-box-title">{{ 'SEARCH.EXPO_TITLE' | translate }}</div>
        <div class="expo-subtitle">
          <div class="expo-icon">
            <img alt="" src="assets/images/expo-gordon-now-icon.svg" />
          </div>
          <p class="expo-chat">{{ 'SEARCH.EXPO_CHAT' | translate }}</p>
          <p class="expo-recomend">{{ 'SEARCH.EXPO_RECOMMEND' | translate }}</p>
        </div>
        <div class="expo-descrip">
          {{ 'SEARCH.EXPO_DESCRIPTION' | translate }}
        </div>
        <button class="ask-expo-button" (click)="handleExpoClick()">
          {{ 'SEARCH.EXPO_CTA' | translate }}
        </button>
      </div>
    </div>
  }
</div>
