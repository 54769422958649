import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GordonNowChatService } from '../gordon-now/gordon-now-chat.service';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { combineLatest, map, Observable, of } from 'rxjs';
import { FulfillmentType } from 'src/app/core/services/cart/models/cart-record';

@Component({
  selector: 'naoo-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoResultsComponent {
  @Input() isZeroWithOG: boolean;
  @Input() isZeroWithAT: boolean;
  @Input() isCategories: boolean;
  @Input() searchText: string;
  @Input() isMobile: boolean;
  @Input() isInvoiceSearch: boolean;

  private readonly actionType = 'SEARCH_MATERIALS';

  constructor(
    private readonly gnChatService: GordonNowChatService,
    private readonly cartFacade: CartFacade,
  ) {}

  handleExpoClick(): void {
    this.gnChatService.executeAction(this.actionType, this.searchText);
  }

  showExpo(): Observable<boolean> {
    const expoAvailable$ = of(this.gnChatService.isChatbotAvailable());
    const truckSelected$ = this.cartFacade.getSelectedFulfillmentType();

    return combineLatest([expoAvailable$, truckSelected$]).pipe(
      map(([isExpoAvailable, isTruckSelected]) => {
        return isExpoAvailable && isTruckSelected === FulfillmentType.TRUCK;
      }),
    );
  }
}
